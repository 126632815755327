import React from 'react';
import styled from 'styled-components';
import p1 from '../src/images/p1.JPG';
import p2 from '../src/images/p2.JPG';
import p3 from '../src/images/p3.JPG';
import p4 from '../src/images/p4.JPG';
import p5 from '../src/images/p5.JPG';

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  margin-top: 10vh;
`;

const ImagesGrid = styled.div`
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
  grid-template-areas: 
    "image1 image2 image3"
    "image4 image5 .";
  
  grid-template-columns: repeat(3, 1fr);
  
  /* Make images larger */
  @media (min-width: 600px) {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-areas: 
      "image1"
      "image2"
      "image3"
      "image4"
      "image5";
  }
`;

const ImageItem = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease;
  }
`;

const InfoSection = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
`;

const Description = styled.p`
  font-size: 1.1rem;
  color: #555;
  margin: 1rem 0;
    text-align: center;
`;

const Button = styled.button`
  margin-top: 1.5rem;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #e67e22;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #cf670e;
  }
`;

function Product() {
  // Handle button click to open WhatsApp with a prefilled message
  const handleBuyNowClick = () => {
    const phoneNumber = '+918956363770';  // Replace with the actual phone number including country code, e.g., '919876543210'
    const message = encodeURIComponent("Hello, I am interested in purchasing this product.");
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <ProductContainer>
      <Title>Products</Title>
      <Description>
        This is a detailed description of the product. It provides all the necessary information about the product’s features, benefits, and uses.
      </Description>
    
      <ImagesGrid>
        <ImageItem style={{ gridArea: "image1" }}>
          <img src={p1} alt="Product Image 1" />
        </ImageItem>
        <ImageItem style={{ gridArea: "image2" }}>
          <img src={p2} alt="Product Image 2" />
        </ImageItem>
        <ImageItem style={{ gridArea: "image3" }}>
          <img src={p3} alt="Product Image 3" />
        </ImageItem>
        <ImageItem style={{ gridArea: "image4" }}>
          <img src={p4} alt="Product Image 4" />
        </ImageItem>
        <ImageItem style={{ gridArea: "image5" }}>
          <img src={p5} alt="Product Image 5" />
        </ImageItem>
      </ImagesGrid>

      <InfoSection>
        <Button onClick={handleBuyNowClick}> Buy Now </Button>
      </InfoSection>
    </ProductContainer>
  );
}

export default Product;
