import React from 'react'
import ss11 from './images/Hair.jpg';
import ss12 from './images/Dandruff.png';
import ss13 from './images/laser-hair-removal1.jpg';
import H1 from './images/Premature Greying1.webp';
import H2 from './images/alo.jpg';
import H3 from './images/Hair Loss Treatment.jpg';

function Hairtreament() {
  return (
    <div>

         <div className="service-section py-5" style={{marginTop:'100px'}}>
                            <h3 className="section-title text-center mb-4">Hair Treatments</h3>
                            <div className="row text-center">
                                <div className="col-lg-4 col-md-6 mb-4" style={{marginTop:'100px'}}>
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss11} alt="Hair PRP Treatment" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Hair PRP Treatment</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss12} alt="Dandruff Therapy" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4> Anti Dandruff Therapy</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={H1} alt="Dandruff Therapy" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4> Premature Greying</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={H2} alt="Dandruff Therapy" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Alopecia</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={H3} alt="Dandruff Therapy" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Hair Loss Treatment</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss13} alt="Laser Hair Reduction" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Laser Hair Reduction</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
  )
}

export default Hairtreament